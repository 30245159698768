import { EVENT_TYPE } from "../storecontextlayer/enums/event-type";
class ContextHeader extends lm.Composant {
  constructor(componentName) {
    super(componentName);
    const contextHeaderSelector = document.querySelector(`[data-selectorid='context-header']`);
    const storeHeaderSelector = contextHeaderSelector.querySelector(".js-context-store-name-button");
    const openContextLayerEvent = new Event("openStoreContextLayer");
    storeHeaderSelector.addEventListener(EVENT_TYPE.CLICK, () => {
      window.dispatchEvent(openContextLayerEvent);
    });
  }
}
lm.DOMReady(() => {
  new ContextHeader('contextheader');
});